<template>
  <div>
    <v-app-bar app color="#2b3991" flat class="headerformat">
      <v-container class="py-0 fill-height" v-if="InAccountSelect">
        <img src="/whitelogo.png" alt="Language Monster" style="height: 45px" class="my-3 d-none d-md-flex"
          @click="OpenProjects" />
        <img src="/MonsterSmallLogo.png" alt="Language Monster" style="height: 45px" class="my-3 d-flex d-md-none"
          @click="OpenProjects" />
        <v-spacer></v-spacer>

        <v-btn icon @click="viewSelectLanguage" color="white" class="mr-2">
          <v-icon>mdi-translate</v-icon>
        </v-btn>
        <v-menu transition="slide-y-transition" bottom left v-if="isAuthenticated">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar color="#6d2bc1" size="45" style="color: white" v-bind="attrs" v-on="on">{{
              Initials }}</v-avatar>
          </template>
          <v-card flat>
            <v-list>
              <v-list-item>
                <v-list-item-avatar color="#6d2bc1" style="color: white">
                  {{ Initials }}
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{
                    CurrentUser.fullName
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $auth.user.email
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-divider></v-divider>

          <v-list>
            <v-list-item @click="logOut">
              <v-list-item-title>{{ $t("LogOut") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>
      <v-container class="py-0 fill-height" v-else>
        <img src="/whitelogo.png" alt="Language Monster" style="height: 45px" class="my-3 d-none d-md-flex"
          @click="OpenProjects" />
        <img src="/MonsterSmallLogo.png" alt="Language Monster" style="height: 45px" class="my-3 d-flex d-md-none"
          @click="OpenProjects" />

        <v-btn text @click="OpenProjects" color="white" class="ml-3 mt-2 d-none d-sm-flex"
          :class="{ 'closedButton': !SearchClose }">
          {{ $t("Projects") }}
        </v-btn>
        <v-btn text @click="OpenTasks" color="white" class="ml-3 mt-2 d-none d-sm-flex"
          :class="{ 'closedButton': !SearchClose }">
          {{ $t("Tasks") }}
        </v-btn>
        <v-btn text @click="OpenOrders" color="white" class="ml-3 mt-2 d-none d-sm-flex"
          :class="{ 'closedButton': !SearchClose }" v-if="hasPermissionAnyProject('ViewOrders') ||
            hasPermissionAnyProject('ManageOrder') ||
            CanManageAccount
            ">
          {{ $t("Orders") }}
        </v-btn>
        <v-btn text @click="viewAccount" color="white" v-if="CanManageTeam" class="ml-3 mt-2 d-none d-sm-flex"
          :class="{ 'closedButton': !SearchClose }">
          {{ $t("Account") }}
        </v-btn>
        <v-spacer></v-spacer>

        <v-responsive max-width="300" class="mr-3 d-none d-lg-flex">
          <v-combobox :placeholder="$t('Search')" :items="searchItems" dense flat hide-details rounded solo
            background-color="white" prepend-inner-icon="mdi-magnify" v-model="SearchTerm" clearable
            :loading="isSearchLoading" hide-no-data append-icon="" item-text="matchedSearchTerm"
            item-value="matchedSearchTerm" no-filter @input.native="setFilterString" @click:clear="ClearFilterString">
            <template v-slot:prepend-item v-if="IsInProject">
              <v-list-item ripple @mousedown.prevent v-if="ShowProjectResults">
                <v-list-item-content>
                  <v-list-item-title class="pointer" @click="SearchToAccount">
                    {{ $t("SearchAccount") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t("ShowingResults", {
                    ProjectName: CurrentProjectName
                  }) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item ripple @mousedown.prevent v-else>
                <v-list-item-content>
                  <v-list-item-title class="pointer" @click="SearchToProject">
                    {{ $t("SearchCurrentProject") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t("ShowingAccountResults") }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>

            <template v-slot:item="{ item }">
              <v-list-item-content @click="HandleClickSearch(item)">
                <v-list-item-title v-html="item.matchedSearchTerm"></v-list-item-title>
                <v-list-item-subtitle>{{ $t(item.matchObject) }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ item.projectName }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-combobox>
        </v-responsive>


        <v-combobox @focus="SearchClose = false" @blur="onBlurOfSearch" :placeholder="$t('Search')"
          class="mr-3 d-flex d-lg-none searchField" :class="{ 'closed': SearchClose }" :items="searchItems" dense flat
          hide-details rounded solo background-color="white" prepend-inner-icon="mdi-magnify" v-model="SearchTerm"
          clearable :loading="isSearchLoading" no-filter @input.native="setFilterString" hide-no-data append-icon=""
          @click:clear="ClearFilterString" item-text="matchedSearchTerm" item-value="matchedSearchTerm">
          <template v-slot:prepend-item v-if="IsInProject">
            <v-list-item ripple @mousedown.prevent v-if="ShowProjectResults">
              <v-list-item-content>
                <v-list-item-title class="pointer" @click="SearchToAccount">
                  {{ $t("SearchAccount") }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t("ShowingResults", {
                  ProjectName: CurrentProjectName
                }) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item ripple @mousedown.prevent v-else>
              <v-list-item-content>
                <v-list-item-title class="pointer" @click="SearchToProject">
                  {{ $t("SearchCurrentProject") }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t("ShowingAccountResults") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>

          <template v-slot:item="{ item }">
            <v-list-item-content @click="HandleClickSearch(item)">
              <v-list-item-title v-html="item.matchedSearchTerm"></v-list-item-title>
              <v-list-item-subtitle>{{ $t(item.matchObject) }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ item.projectName }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-combobox>



        <v-btn icon @click="viewSelectLanguage" color="white" class="mr-2" :class="{ 'smallClosedButton': !SearchClose }">
          <v-icon>mdi-translate</v-icon>
        </v-btn>

        <v-menu transition="slide-y-transition" bottom left v-if="isAuthenticated">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar color="#6d2bc1" size="45" style="color: white" v-bind="attrs"
              :class="{ 'smallClosedButton': !SearchClose }" v-on="on">{{ CurrentUser.firstName.charAt(0)
              }}{{ CurrentUser.surName.charAt(0) }}</v-avatar>
          </template>
          <v-card flat>
            <v-list>
              <v-list-item>
                <v-list-item-avatar color="#6d2bc1" style="color: white;justify-content:center;">
                  {{ CurrentUser.firstName.charAt(0)
                  }}{{ CurrentUser.surName.charAt(0) }}
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{
                    CurrentUser.fullName
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $auth.user.email
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>

          <v-list>
            <v-list-item class="d-flex backgroundBrand"><v-list-item-title class="backgroundBrand">
                {{ AccountName }}</v-list-item-title></v-list-item>
            <v-list-item class="d-flex d-sm-none" @click="OpenProjects"><v-list-item-title>
                {{ $t("Projects") }}</v-list-item-title></v-list-item>
            <v-list-item class="d-flex d-sm-none" @click="OpenTasks"><v-list-item-title>{{
              $t("Tasks")
            }}</v-list-item-title></v-list-item>
            <v-list-item class="d-flex d-sm-none" @click="OpenOrders" v-if="hasPermissionAnyProject('ViewOrders') ||
                hasPermissionAnyProject('ManageOrder') ||
                CanManageAccount
                "><v-list-item-title>{{
      $t("Orders")
    }}</v-list-item-title></v-list-item>

            <v-list-item class="d-flex d-sm-none" @click="viewAccount" v-if="CanManageTeam"><v-list-item-title>{{
              $t("Account")
            }}</v-list-item-title></v-list-item>
            <v-list-item class="d-flex" @click="ManagePaymentMethod" v-if="CanManageAccount"><v-list-item-title>{{
              $t("ManagePaymentMethod")
            }}</v-list-item-title></v-list-item>
            <v-list-item class="d-flex" @click="SwtichAccount"><v-list-item-title>{{
              $t("SwtichAccount")
            }}</v-list-item-title></v-list-item>
            <v-list-item class="d-flex" @click="ChangePassword"><v-list-item-title>{{
              $t("ChangePassword")
            }}</v-list-item-title></v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item @click="logOut">
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-container class="mainContainer">
        <router-view />
      </v-container>
    </v-main>
    <IoT> </IoT>
    <ChatWindow v-if="!InAccountSelect"></ChatWindow>
    <ChangePassword v-bind:openDialog="OpenPasswordDialog" :TeamMemberId="Id"
      v-on:ClosePasswordResetModal="ClosePasswordDialog">
    </ChangePassword>
    <SelectLanguage :openDialog="OpenSelectLanguage" v-on:CloseSelectLanguage="CloseSelectLanguage">

    </SelectLanguage>
  </div>
</template>
<script lang="ts">
import IoT from "@/components/iot.vue";
import ChatWindow from "@/views/Chat/ChatWindow.vue";
import mixins from "vue-typed-mixins";
import Common from "@/Mixins/Common";
import Authentication from "@/Mixins/Authentication";
import Account from "@/Mixins/Account";
import ChangePassword from "@/views/Users/ChangePassword.vue";
import SelectLanguage from "@/views/GlobalAccount/ChangeLanguage.vue";
import { Trans } from '@/plugins/trans';
import Project from "@/Types/Project";
import CoreLanguage from "@/Types/CoreLanguages";
import Country from "@/Types/Country";

export default mixins(Common, Authentication, Account).extend({
  components: {
    IoT,
    ChatWindow,
    ChangePassword,
    SelectLanguage
  },
  mounted() {
    if (!this.hasLanguages && !this.$store.state.LanguagesLoading) {
      this.$store
        .dispatch("GetLanguages")
        .then(() => { })
        .catch((e: any) => {
          this.LogError(e);
          this.PushToRoute("MajorError");
        });
    }
    this.$root.$on("Unauthorized", () => {
      this.SetUnauthorized();
    });
  },
  data: function () {
    return {
      drawer: false,
      OpenPasswordDialog: false,
      IsReAuthenticating: false,
      OpenSelectLanguage: false,
      SearchClose: true,
      SearchTerm: "",
      isSearchLoading: false,
      select: null,
      searchItems: [],
      filterString: "",
      timeout: {} as any,
      lastTimeStamp: 0,
      ShowProjectResults: true,
      SearchHasClick: false
    };
  },
  name: "LangaugeWrap",
  computed: {
    Initials(): string {
      if(this.CurrentUser == undefined || this.CurrentUser.firstName == null || this.CurrentUser.surName == null)
      {
        return  "";
                  }
      return this.CurrentUser.firstName.charAt(0)+ this.CurrentUser.surName.charAt(0);
    },
    Language(): string {
      return this.$route.params.lang;
    },
    IsInProject(): boolean {
      if (this.$route.path.indexOf("projects") != -1 && this.$route.params["id"] != undefined) {
        return true;
      }
      return false;
    },
    InAccountSelect(): Boolean {
      return this.currentRoute == "AccountSelector";
    },
    currentRoute(): string | null | undefined {
      return this.$route.name;
    },
    AccountName(): string {
      if (this.AccountOwnership == null || this.AccountOwnership.companyName == null) {
        return "";
      }
      return this.AccountOwnership.companyName;
    },
    CurrentProject(): Project | null {
      if (
        this.$store.state.Projects !== undefined &&
        this.$store.state.Projects.length !== 0 && this.IsInProject
      ) {
        const c = this.$store.state.Projects.find(
          (p: any) => p.externalId === this.$route.params["id"]
        );
        if (c == undefined) {
          return null;
        } else {
          return c;
        }
      } else {
        return null;
      }
    },
    CurrentProjectName(): string {
      if (this.CurrentProject == null) {
        return "";
      }
      return this.CurrentProject.name;
    },
    ProjectId(): string {
      if (this.CurrentProject == null) {
        return "";
      }
      return this.CurrentProject.externalId;
    }
  },
  watch: {
    SupportedLanguages() {
      if (this.CommonCurrentLanguage != this.SupportedLanguages && this.hasLanguages) {
        this.FormatLanguageNames();
        this.CommonCurrentLanguage = this.SupportedLanguages;
      }
    },
    Language() {
      Trans.changeLanguage(this.Language);
    },
    SearchTerm() {
      if (this.SearchTerm != null && this.SearchTerm != "") {
        this.$nextTick(function () {
          if (this.SearchHasClick) {
            this.searchItems = [];
            this.SearchTerm = "";
            this.SearchHasClick = false;
          }
        });
      }
    },
    ShowProjectResults() {
      this.runQuerySelections(this.filterString, this.getTimeStamp())
    },
    async auth0Loading() {
      if (!this.auth0Loading) {
        try {
          const accessToken = await this.$auth.getTokenSilently();
          this.$store
            .dispatch("SetAuthToken", accessToken)
            .then(() => { })
            .catch((e: any) => {
              this.LogError(e);
              this.PushToRoute("MajorError");
            });
        } catch (e: any) {
          let lang = this.$route.params.lang;

          if (!Trans.isLangSupported(lang)) {
            lang = Trans.getUserSupportedLang();
          }
          if (e.error === "login_required") {
            this.IsReAuthenticating = false;

            this.$auth.loginWithRedirect({
              ui_locales: lang,
            });
          }
          if (e.error === "consent_required") {
            this.IsReAuthenticating = false;
            this.$auth.loginWithRedirect({
              ui_locales: lang,
            });
          }
          throw e;
        }
      }
    },
  },
  methods: {
    FormatLanguageNames() {
      let langs = this.$store.state.Languages as CoreLanguage[];
      langs.forEach((element: CoreLanguage) => {
        const langTitle = `Language-${element.isoLocalId}`;

        element.languageTitle = this.$i18n.t(langTitle) + " (" + element.isoLocalId + ")"
      });
      langs = langs.sort(function (a: CoreLanguage, b: CoreLanguage) {
        let nameA = a.languageTitle.toUpperCase(); // ignore upper and lowercase
        var nameB = b.languageTitle.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      this.$store.commit("UpdateLanguages", langs);


      let list = this.$store.state.Countries as Country[];
      list.forEach((element: Country) => {
        element.name = this.$i18n.t("Country-" + element.id.toUpperCase()).toString();
      });

      list = list.sort(function (a: Country, b: Country) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      this.$store.commit("UpdateCountries", list);

    },
    onBlurOfSearch() {
      if (this.filterString == "" || this.filterString == null) {
        this.SearchClose = true;
        this.isSearchLoading = false;
        this.searchItems = [];
        this.SearchTerm = "";
      }
    },
    ClearFilterString() {
      this.filterString = '';
      this.searchItems = [];
    },
    SearchToAccount() {
      this.ShowProjectResults = false;
    },
    SearchToProject() {
      this.ShowProjectResults = true;
    },
    setFilterString(e: any) {
      if (!e) {
        this.filterString = ''
      } else if (typeof e === 'string') {
        this.filterString = e
      } else if (typeof e === 'object') {
        this.filterString = e.srcElement.value
      }

      this.querySelections(this.filterString)
    },
    viewSelectLanguage() {
      this.OpenSelectLanguage = true;
    },
    CloseSelectLanguage() {
      this.OpenSelectLanguage = false;
    },
    logOut() {
      this.$auth.logout({
        returnTo: process.env.VUE_APP_PUBLICSITE,
      });
    },
    ChangePassword() {
      this.OpenPasswordDialog = true;
    },
    ClosePasswordDialog() {
      this.OpenPasswordDialog = false;
    },
    SwtichAccount() {
      this.PushToRoute("AccountSelector");
    },
    OpenProjects() {
      if (this.$route.name != "projects") {
        this.PushToThisAccountRoute("projects");
      }
    },
    OpenOrders() {
      if (this.$route.name != "viewOrders") {
        this.PushToThisAccountRoute("viewOrders");
      }
    },
    viewAccount() {
      if (this.$route.name != "viewAccount") {
        this.PushToThisAccountRoute("viewAccount");
      }
    },
    ManagePaymentMethod() {
      this.PushToThisAccountRoute("ManagePaymentMethod");
    },
    OpenTasks() {
      if (this.$route.name != "viewTasksOverview") {
        this.PushToThisAccountRoute("viewTasksOverview");
      }
    },
    async SetUnauthorized() {
      if (!this.auth0Loading && !this.IsReAuthenticating) {
        this.IsReAuthenticating = true;
        //console.log("IsReAuthenticating");
        try {
          const accessToken = await this.$auth.getTokenSilently();
          this.$store
            .dispatch("SetAuthToken", accessToken)
            .then((response) => {
              if (response) {
                // alert("bad");
                //this.PushToRoute("AccountSelector");
                this.IsReAuthenticating = false;
              }
            })
            .catch((e: any) => {
              this.LogError(e);
              this.PushToRoute("MajorError");
            });
        } catch (e: any) {
          let lang = this.$route.params.lang;

          if (!Trans.isLangSupported(lang)) {
            lang = Trans.getUserSupportedLang();
          }
          if (e.error === "login_required") {
            this.IsReAuthenticating = false;
            this.$auth.loginWithRedirect({
              ui_locales: lang,
            });
          }
          if (e.error === "consent_required") {
            this.IsReAuthenticating = false;
            this.$auth.loginWithRedirect({
              ui_locales: lang,
            });
          }
          throw e;
        }
      }
    },
    querySelections(v: string) {
      window.clearTimeout(this.timeout);
      if (v.trim().length != 0) {
        if ((v.trim().length % 3) == 0) {
          this.runQuerySelections(v, this.getTimeStamp())
        } else {
          this.timeout = window.setTimeout(() => this.runQuerySelections(v, this.getTimeStamp()), 300);
        }
      }


    },
    getTimeStamp(): number {
      var isPerformanceSupported = (
        window.performance &&
        window.performance.now() &&
        window.performance.timing &&
        window.performance.timing.navigationStart
      );

      var timeStampInMs = (
        isPerformanceSupported ?
          window.performance.now() +
          window.performance.timing.navigationStart :
          Date.now()
      );
      return timeStampInMs;
    },
    runQuerySelections(v: string, timestamp: number) {
      this.isSearchLoading = true
      window.clearTimeout(this.timeout);
      // Simulated ajax query
      setTimeout(() => {
        this.$store
          .dispatch("DoSearch", { searchTerm: v, AccountId: this.AccountId, ShowProjectResults: this.ShowProjectResults, ProjectId: this.ProjectId })
          .then((results: any) => {
            if (timestamp > this.lastTimeStamp) {
              this.lastTimeStamp = timestamp;
              results.forEach((element: any) => {
                element.searchTerms.forEach((b: string) => {
                  element.matchedSearchTerm = this.boldString(element.matchedSearchTerm, b);
                });
                element.matchedSearchTerm
              });
              this.searchItems = results;
            }


          })
          .catch((e: any) => {
            this.LogError(e);
            // this.PushToRoute("MajorError");
          });



        this.isSearchLoading = false
      }, 500)
    },
    boldString(str: string, substr: string) {
      var strRegExp = new RegExp(substr, 'i');
      return str.replace(strRegExp, '<strong>' + substr + '</strong>');
    },
    HandleClickSearch(term: any) {
      this.filterString = "";
      this.SearchTerm = "";
      const lang = this.$route.params.lang;
      const accountId = this.$route.params.accountId;
      const id = term.projectExternalId;
      this.SearchHasClick = true;
      if (term.matchObject == "Project") {
        const name = "viewproject";
        const to = this.$router.resolve({
          name,
          params: { accountId, lang, id },
        });
        //alert(to.location);
        this.ShouldRoute(to);
      }
      else if (term.matchObject == "ProjectKey" || term.matchObject == "ProjectKeyDetails") {

        const name = "viewsinglekey";
        const keyId = term.projectKeyExternalId;
        const sourceLanguage = lang;
        const targetLanguage = "fr";

        const to = this.$router.resolve({
          name,
          params: { accountId, lang, id, keyId, sourceLanguage, targetLanguage },
        });
        //alert(to.location);
        this.ShouldRoute(to);
      }
      else if (term.matchObject == "ProjectGlossary") {

        let name = "viewGlossary";
        const lang = this.$route.params.lang;
        let projectId = this.Id;
        const to = this.$router.resolve({
          name,
          params: { lang, projectId },
          query: { GlossaryId: term.matchObjectExternalId },
        });
        this.ShouldRoute(to);

      }
      else {
        alert(term.matchObject);
      }
    },
    ShouldRoute(to: any) {
      if (this.$route.fullPath != to.route.fullPath) {
        this.$router.push(to.location);
      }
    }
  },
});
</script>
<style>
.v-main__wrap {
  min-height: 100vh;
}

.v-card.KeyTitleWrap,
.KeyTitleWrap {
  background-color: #2b3991 !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  color: white;
}

.KeyTitleWrap .v-card {
  background-color: transparent;
}

.closed {
  width: 28px;
  max-width: 28px;
  margin: 0 !important;
  margin-right: 0 !important;
  cursor: pointer;
  overflow: hidden;
}


.closed .mdi-magnify::before {
  color: white;
}

.searchField .mdi-magnify::before {
  transition: color 0.4s linear;
}

.closed .v-input__slot {
  cursor: pointer !important;
}

.closed .white {
  background: transparent !important;
  padding: 0 !important;
}

.searchField {
  transition: max-width 0.4s, background-color 0.4s linear;
}

.searchField .white {
  transition: max-width 0.4s, background-color 0.4s linear;
}




@media (max-width: 520px) {
  .v-btn.smallClosedButton {
    display: none !important;
  }
}

@media (max-width: 460px) {
  .v-avatar.smallClosedButton {
    display: none !important;
  }
}

@media (max-width: 1264px) {
  .v-btn.closedButton {
    display: none !important;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1550px;
  }
}

.headerformat {
  color: white;
  border-bottom: 7px solid #6d2bc1 !important;
  height: unset !important;
}





.headerformat .v-toolbar__content {
  height: unset !important;
}

.v-main {
  padding: 84px 0px 0px !important;
}

.mainContainer {
  padding: 25px 0 !important;
}

.brandText {
  color: #6c2bc1 !important;
}

.blueBrandTest {
  color: #2b3990 !important;
}

.backgroundBrand {
  background-color: #6c2bc1 !important;
  border-color: #6c2bc1 !important;
  color: white;
}

.subBackgroundBrand {
  background-color: rgba(43, 57, 144, 0.3) !important;
}

.backgroundBlueBrand {
  background-color: #2b3990 !important;
  border-color: #2b3990 !important;
  color: white;
}

.v-btn__content .backgroundBrand.v-btn,
.backgroundBrand .v-btn__content {
  color: white !important;
  letter-spacing: normal !important;
}

.v-btn__content {
  letter-spacing: normal !important;
}


.KeyTitleWrap .v-card {
  background-color: transparent;
}

.v-btn {
  text-transform: none !important;
}

.backgroundBrand .v-btn {
  font-weight: normal;
}

.shadowWrap {
  border-radius: 15px !important;
  box-shadow: 0px 0px 4px 2px #dedede !important;
  overflow: hidden;
}

.v-card.KeyTitleWrap,
.KeyTitleWrap {
  background-color: #2b3991 !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  color: white;
}

.alternatingbackground,
.alternatingrow {
  background-color: #e8ebf4 !important;
}

.KeyTitleWrap .theme--light.v-card {
  color: white;
}

.v-dialog {
  border-radius: 15px !important;
}

.v-select.v-select--chips.v-select--chips--small .v-select__selections {
  min-height: 32px !important;
}

.theme--light.v-chip:not(.v-chip--active) {
  background-color: #dddfe8 !important;
}

.pointer {
  cursor: pointer;
}

.subRow:nth-child(odd) {
  background-color: #e8ebf4;
}

.AtlRow:nth-child(even) {
  background-color: #e8ebf4;
}
</style>