<template>
  <div>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
      <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script >
export default {
  name: "IoT",
  data() {
    return {
      LastAccount: "",
      mqttClient: "",
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        title: null,
        visible: false,
      },
      timeout: 7500,
    };
  },
  methods: {
    GetDownload(ProjectId, Message) {
      this.$store
        .dispatch("GetDownloadLink", {
          ProjectId: ProjectId,
          RequestId: Message,
          AccountId: this.AccountId,
        })
        .then((data) => {
          this.downloadFile(data.downloadUrl);
        });
    },
    downloadFile(sUrl) {
      //iOS devices do not support downloading. We have to inform user about this.
      if (/(iP)/g.test(navigator.userAgent)) {
        alert(
          "Your device does not support files downloading. Please try again in desktop browser."
        );
        return false;
      }

      //If in Chrome or Safari - download via virtual link click
      if (this.isChrome || this.isSafari) {
        //Creating new link node.
        var link = document.createElement("a");
        link.href = sUrl;

        if (link.download !== undefined) {
          //Set HTML5 download attribute. This will prevent file from opening if supported.
          var fileName = sUrl.substring(sUrl.lastIndexOf("/") + 1, sUrl.length);
          link.download = fileName;
        }

        //Dispatching click event.
        if (document.createEvent) {
          var e = document.createEvent("MouseEvents");
          e.initEvent("click", true, true);
          link.dispatchEvent(e);
          return true;
        }
      }

      // Force file download (whether supported by server).
      if (sUrl.indexOf("?") === -1) {
        sUrl += "?download";
      }

      window.open(sUrl, "_self");
      return true;
    },
    OpenMqtt() {
      window.global = window;
      window.process = require("process/browser");
      window.Buffer = window.Buffer || require("buffer").Buffer;
      const mqtt = require("mqtt");
      console.log("IoT component created");

      const clientId = "askJames-" + Math.floor(Math.random() * 100000000 + 1);

      this.mqttClient = mqtt.connect(
        "wss://aikwjtnyp0dc-ats.iot.us-west-2.amazonaws.com/mqtt",
        {
          // transformWsUrl: transformWsUrl,
          username: this.$store.state.authToken,
          password: "",
          clientId: clientId,
          regionName: "us-west-2",
          debug: true,
        }
      );

      let that = this;

      this.mqttClient.on("connect", function () {
        //this.mqttClient.subscribe('topic_1')
        if (that.HasAccount) {
          that.mqttClient.subscribe(that.AccountId);
          //console.log("subscribe to account " + that.AccountId);
          //alert(that.AccountId);
        }
        if (that.HasUser) {
          that.mqttClient.subscribe(that.CurrentUser.externalId);
          //console.log("subscribe to user " + that.CurrentUser.externalId);
          //alert(that.Account);
        }
      });

      this.mqttClient.on("message", function (topic, message) {
        // message is Buffer
        //alert(message.toString());
        const msg = JSON.parse(message.toString());
        console.log("mqttClient message: ", msg);

        console.log(msg.Type);
        // Send the message back to parent component
        switch (msg.Type) {
          case "NewProjectAdded":
          case "UpdateProject":
            that.$store
              .dispatch("RefreshProject", {
                projectId: msg.ProjectId,
                AccountId: that.AccountId,
              })
              .then(() => { })
              .catch((error) => {
                this.CheckError(error);
               });
            break;
          case "AccountUpdate":
            that.$store
              .dispatch("GetAccountInformation", msg.Message)
              .then(() => {
                that.$root.$emit(msg.Type, msg);
              })
              .catch(() => { });
            break;
          case "DeleteProject":
            that.$store.commit("RemoveProject", msg.ProjectId);
            break;
          case "DownloadFileReady":
            that.GetDownload(msg.ProjectId, msg.Message);
            break;
          case "ImageProcessComplete":
            if (msg.ActionCompletedSuccessfully) {
              that.snackbar = {
                color: "success",
                icon: "mdi-check-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 3000,
                title: that.$i18n.t("ImageProceeingComleted"),
                text: "That worked, hoorah.",
                visible: true,
              };
              that.$root.$emit(msg.Type, msg);
            } else {
              that.snackbar = {
                color: "error",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 3000,
                title: that.$i18n.t("ImageProceeingFailed"),
                text: "That worked, hoorah.",
                visible: true,
              };
            }

            break;
          default:
            that.$root.$emit(msg.Type, msg);
        }
      });
      //   this.mqttClient.end();
      // });
      this.mqttClient.on("error", function (message) {
        // message is Buffe
        alert(message.toString());
        this.mqttClient.end();
      });
    },
  },
  computed: {
    HasAccount() {
      if (this.AccountId == null || this.AccountId == undefined) {
        return false;
      }
      return true;
    },
    AccountId() {
      return this.$route.params.accountId;
    },
    isChrome() {
      return navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
    },
    isSafari() {
      return navigator.userAgent.toLowerCase().indexOf("safari") > -1;
    },
    HasUser() {
      if (
        this.$store.state.CurrentUser == null ||
        this.$store.state.CurrentUser == undefined ||
        this.$store.state.CurrentUser.length === 0
      ) {
        return false;
      }
      return true;
    },
    CurrentUser() {
      return this.$store.state.CurrentUser;
    },
    isAuthenticated() {
      return this.$store.state.IsAuthenticated;
    },
  },
  watch: {
    AccountId() {
      if (
        this.LastAccount != undefined &&
        this.LastAccount !== null &&
        this.LastAccount.length != 0 &&
        this.mqttClient != null
      ) {
        this.mqttClient.unsubscribe(this.LastAccount);
      }
      this.LastAccount = this.AccountId;
    },
    isAuthenticated() {
      if (this.isAuthenticated && this.mqttClient != null) {
        this.OpenMqtt();
      }
    },
    // HasAccount() {
    //   if (this.HasAccount && this.mqttClient != null) {
    //     this.mqttClient.subscribe(this.AccountId);
    //     //console.log("subscribe to account " + this.Account);
    //   }
    // },
    HasUser() {
      if (this.HasUser && this.mqttClient != null) {
        this.mqttClient.subscribe(this.CurrentUser.externalId);
        //console.log("subscribe to user " + this.CurrentUser.externalId);
        //alert(that.Account);
      }
    },
  },
  created() {
    if (this.isAuthenticated && this.mqttClient != null) {
      this.OpenMqtt();
    }
  },
  mounted() {
    this.Loading = true;
    this.$root.$on("ListenToProject", (projectId) => {
      if (this.mqttClient != null) {
        this.mqttClient.subscribe(projectId);
        //console.log("subscribe to " + projectId);
      }
    });
    this.$root.$on("StopListenToProject", (projectId) => {
      if (this.mqttClient != null) {
        this.mqttClient.unsubscribe(projectId);
        //console.log("unsubscribe to " + projectId);
      }
    });
    this.$root.$on("ListenToIntergration", (intergrationId) => {
      if (this.mqttClient != null) {
        this.mqttClient.subscribe(intergrationId);
        //console.log("subscribe to " + intergrationId);
      }
    });
    this.$root.$on("StopListenToIntergration", (intergrationId) => {
      if (this.mqttClient != null) {
        this.mqttClient.unsubscribe(intergrationId);
        //console.log("subscribe to " + intergrationId);
      }
    });
  },
  beforeDestroy() {
    if (this.LastAccount != null && this.LastAccount != undefined) {
      this.mqttClient.unsubscribe(this.LastAccount);
    }
  },
};
</script>
