export default (value:string) => {
    const date = new Date(value)
    const today = new Date()

    if(date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear())
    {
        return date.toLocaleTimeString([], {timeStyle: 'short'});
    }


    return date.toLocaleDateString(['en-AU'], {month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})  //if you want, you can change locale date string
} 