import Vue from "vue";
import VueRouter from "vue-router";
import langwrap from "../views/languagewrap.vue";
import i18n from "../i18n";
import { Trans } from "@/plugins/trans";
import { authenticationGuard } from "@/auth/authenticationGuard.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/bitbucket",
    name: "bitbucket",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "OauthReturn" */ "../views/Repo/OauthReturn.vue"
      ),
    beforeEnter: authenticationGuard,
  },
  {
    path: "/azure",
    name: "AzureDevOps",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "OauthReturn" */ "../views/Repo/OauthReturn.vue"
      ),
    beforeEnter: authenticationGuard,
  },
  {
    path: "/github",
    name: "github",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "OauthReturn" */ "../views/Repo/OauthReturn.vue"
      ),
    beforeEnter: authenticationGuard,
  },
  {
    path: "/slack",
    name: "slack",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "OauthReturn" */ "../views/Repo/OauthReturn.vue"
      ),
    beforeEnter: authenticationGuard,
  },
  // {
  //   path: "/joinnow",
  //   name: "JoinNow",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "OauthReturn" */ "../views/Auth/SignUp.vue"
  //     ),
  // },
  // {
  //   path: "/signin",
  //   name: "SignIn",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "OauthReturn" */ "../views/Auth/SignIn.vue"
  //     ),
  // },
  {
    path: "/entry/:lang/joinnow",
    name: "JoinNow",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "OauthReturn" */ "../views/Auth/SignUp.vue"
      ),
  },
  {
    path: "/entry/:lang/signin",
    name: "SignIn",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "OauthReturn" */ "../views/Auth/SignIn.vue"
      ),
  },
  {
    path: "/:lang/",
    component: langwrap,
    beforeEnter(to: any, from: any, next: any) {
      const lang = to.params.lang;
      if (!Trans.isLangSupported(lang)) {
        return next(Trans.getUserSupportedLang());
      }
      if (i18n.locale === lang) {
        return next();
      }
      import(`@/locales/${lang}.json`).then((msg) => {
        i18n.setLocaleMessage(lang, msg.default || msg);
        i18n.locale = lang;
        Trans.setI18nLanguageInServices(lang);
        return next();
      });
    },
    children: [
      {
        path: "/",
        name: "AccountSelector",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "ManagePaymentMethod" */ "../views/GlobalAccount/AccountSelector.vue"
          ),
          beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects",
        name: "projects",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "ListProjects" */ "../views/Project/ListProjects.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/multilingual",
        name: "viewproject",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewproject" */ "../views/Project/ViewProject.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/bilingual/:sourceLanguage/:targetLanguage",
        name: "bilingualviewproject",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewproject" */ "../views/Project/ViewProject.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/key/:keyId/:sourceLanguage/:targetLanguage",
        name: "viewsinglekey",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewproject" */ "../views/Project/ViewProject.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/key/:sourceLanguage/:targetLanguage/single",
        name: "viewsinglekeynokey",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewproject" */ "../views/Project/ViewProject.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/Intergration",
        name: "viewIntergration",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewIntergration" */ "../views/Repo/ViewRepo.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/screenshots",
        name: "viewscreenshots",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewscreenshots" */ "../views/Screenshots/ViewScreenshots.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/glossary",
        name: "viewGlossary",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewglossary" */ "../views/Glossary/ViewGlossary.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/tags",
        name: "viewTags",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewtags" */ "../views/Tags/ViewTags.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/Platforms",
        name: "viewPlatforms",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewPlatforms" */ "../views/Platforms/ViewPlatforms.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/automation",
        name: "viewAutomation",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewAutomation." */ "../views/Automation/ViewAutomation.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/automation/new",
        name: "newAutomation",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewNewAutomationation." */ "../views/Automation/NewAutomation.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/automation/:automationId",
        name: "editAutomation",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewEditAutomationation." */ "../views/Automation/EditAutomation.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/tasks",
        name: "viewProjectTasks",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Viewprojecttasks" */ "../views/Tasks/ViewProjectTasks.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/viewtransaction/:id",
        name: "ViewTransaction",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "ViewTransaction" */ "../views/Account/ViewTransaction.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/closeaccount",
        name: "CloseAccount",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "ViewTransaction" */ "../views/Account/CloseAccount.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/tasks/:taskId",
        name: "viewTasksById",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewtasks" */ "../views/Tasks/ViewTasks.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/tasks/overview",
        name: "viewTasksOverview",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "tasksoverview" */ "../views/Tasks/ListTasks.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/tasks/user/:id",
        name: "viewTasksUser",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "tasksoverview" */ "../views/Tasks/TasksByUser.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/newtask",
        name: "CreateProjectTasks",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "CreateTasks" */ "../views/Tasks/CreateProjectTasks.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/newtask",
        name: "CreateTasks",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "CreateTasks" */ "../views/Tasks/CreateProjectTasks.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/account/user/:id",
        name: "ManageUsers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "manageusers" */ "../views/Users/ManageUsers.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/account/team/:id",
        name: "ManageTeam",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "manageteam" */ "../views/Team/ManageTeam.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/account/createuser",
        name: "CreateUsers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "manageusers" */ "../views/Users/CreateUsers.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/account/createteam",
        name: "CreateTeam",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "manageteam" */ "../views/Team/CreateTeam.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/users",
        name: "viewprojectusers",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewprojectusers" */ "../views/Users/ViewProjectUsers.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/download",
        name: "viewdownload",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewdownload" */ "../views/BuildDownload/ViewDownload.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/Intergration/:repoId",
        name: "viewIntergrationbyid",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewIntergration" */ "../views/Repo/EditRepo.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/projects/:id/Intergration/:repoId/history",
        name: "viewIntergrationHistoryById",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewIntergration" */ "../views/Repo/ViewIntergrationHistory.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/neworder/:id",
        name: "viewNewOrdersProject",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "ordernewtranslations" */ "../views/Orders/OrderNewTranslations.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/neworder",
        name: "viewNewOrders",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "ordernewtranslations" */ "../views/Orders/OrderNewTranslations.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/orders",
        name: "viewOrders",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewOrders" */ "../views/Orders/ViewOrders.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/orders/previous",
        name: "viewOrdersPreviousOrder",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewOrders" */ "../views/Orders/ViewOrders.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/orders/:orderId",
        name: "viewOrder",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewOrders" */ "../views/Orders/ViewOrderById.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: "error",
        name: "MajorError",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "MajorError" */ "../views/ErrorScreen.vue"
          ),
      },
      {
        path: "ProjectFailedLoad",
        name: "ProjectFailedLoad",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "ProjectFailedLoad" */ "../views/ProjectFailedLoad.vue"
          ),
      },
      {
        path: ":accountId/account",
        name: "viewAccount",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "viewAccount" */ "../views/Account/ViewAccount.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      // {
      //   path: 'selectaccount',
      //   name: 'selectAccount',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "selectAccount" */ '../views/Account/SelectAccount.vue'),
      //   beforeEnter: authenticationGuard,
      // },
      {
        path: ":accountId/changeplan",
        name: "changeplan",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "changeplan" */ "../views/Account/ChangePlan.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/changepaymentmethod",
        name: "changepaymentmethod",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "ChangePaymentMethod" */ "../views/Account/ChangePaymentMethod.vue"
          ),
        beforeEnter: authenticationGuard,
      },
      {
        path: ":accountId/ManagePaymentMethod",
        name: "ManagePaymentMethod",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "ManagePaymentMethod" */ "../views/Account/ManagePaymentMethod.vue"
          ),
        beforeEnter: authenticationGuard,
      },
    ],
  },
  {
    // Redirect user to supported lang version.s
    path: "*",
    redirect() {
      let lang = sessionStorage.getItem("userLang");
      if (lang == null || !Trans.isLangSupported(lang)) {
        lang = Trans.getUserSupportedLang();
      }

      return lang;
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
