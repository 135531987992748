<template>
  <div>
    <v-dialog
      v-model="openDialogWatch"
      max-width="600"
      persistent
      no-click-animation
      v-if="!ShowComplete"
    >
      <v-form ref="ResetPasswordAction" lazy-validation>
        <v-card>
          <v-card-title class="headline red white--text px-4" primary-title>
            {{ $t("ChangePassword") }}
            <v-spacer></v-spacer>
            <v-btn icon dark @click="ClosePasswordResetModal" class="ma-0">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="px-4 py-6 text-subtitle-1">
            {{
              $t("AreYouSureChangeYourPassword", 
              )
            }}
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              text
              @click="ClosePasswordResetModal"
              >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
              class="ma-0 ml-2 px-4"
              @click="ResetPasswordAction"
              outlined
              rounded
              color="red"
              :loading="IsRequesting"
            >
              {{ $t("yes") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="openDialogWatch"
      max-width="600"
      persistent
      no-click-animation
      v-else
    >
      <v-form ref="newLanguage" lazy-validation>
        <v-card>
          <v-card-title class="headline backgroundBrand  white--text" primary-title
            >{{ $t("ActionRequested") }}
            <v-spacer></v-spacer>
            <v-btn icon dark @click="ClosePasswordResetModal" class="ma-0">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="px-3 py-6 text-subtitle-1">
            {{ $t("EmailSentChangePassword") }}
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              class="ma-0 ml-2 backgroundBrand pa-4  white--text"
              @click="ClosePasswordResetModal"
              rounded
              >{{ $t("Ok") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <UnhandleErrorDialog :openDialog="OpenGeneralError" v-on:CloseErrorOccured="CloseErrorOccured"></UnhandleErrorDialog>
    <NoPermission :openDialog="IsNoPermissionOpen" v-on:CloseNoPermission="CloseErrorOccured">
    </NoPermission>
  </div>
</template>
<script lang="ts">
import mixins from "vue-typed-mixins";
import Common from "@/Mixins/Common";
import UnhandleErrorDialog from "@/components/UnhandleErrorDialog.vue";
import NoPermission from "@/components/NoPermission.vue";

export default mixins(Common).extend({
  data() {
    return {
      IsRequesting: false,
      openDialogWatch: false,
      ShowComplete: false,
    };
  },
  methods: {
    ClosePasswordResetModal() {
      this.ShowComplete = false;
      this.$emit("ClosePasswordResetModal");
    },
    ResetPasswordAction() {
      this.IsRequesting = true;

      this.$store
        .dispatch("ChangePassword",   {})
        .then(() => {
          
          this.IsRequesting = false;
          this.ShowComplete = true;
        })
        .catch((error) => {
          this.IsRequesting = false;
          this.CheckError(error);
          
        });
    },
  },
  watch: {
    openDialog() {
      this.openDialogWatch = this.openDialog;
    },
  },
  components: {
    UnhandleErrorDialog,
    NoPermission
  },
  props: {
    openDialog: Boolean,
    TeamMemberId: String,
    ProjectId: String,
  },
});
</script>
              