<template>
  <div>
    <v-dialog v-model="openDialogWatch" max-width="600" persistent no-click-animation>
      <v-form ref="DeleteTeamMember" lazy-validation>
        <v-card>
          <v-card-title class="headline red white--text" primary-title>
            {{ $t("NoPermission") }}
            <v-spacer></v-spacer>
            <v-btn icon dark @click="CloseDeleteModal" class="ma-0">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="px-3 pt-6 text-subtitle-1">
            {{
                $t("NoPermissionDetails")
            }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-0 ml-2 px-4 backgroundBrand white--text" @click="CloseDeleteModal" outlined rounded>
              {{ $t("Ok") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  data() {
    return {
      openDialogWatch: false,
    };
  },
  methods: {
    CloseDeleteModal() {
      this.$emit("CloseNoPermission");
    },
  },
  watch: {
    openDialog() {
      this.openDialogWatch = this.openDialog;
    },
  },
  props: {
    openDialog: Boolean,
  },
});
</script>
