import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {
  };
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

const numberFormats = {
  'en': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'es': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'de': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'cs': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'el': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'vi': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'fr': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'ja-JP': {
    currency: {
      style: 'currency', currency: 'JPY'
    }
  }
}


export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(), 
  numberFormats
})
