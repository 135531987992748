import Vue from "vue";
import { Logtail } from "@logtail/browser";
import User from "@/Types/User";
import Country from "@/Types/Country";
import UnhandleErrorDialog from "@/components/UnhandleErrorDialog.vue";
import NoPermission from "@/components/NoPermission.vue";

const logtail = new Logtail("TteudCfa2RgjNFCphH6498Us");

export default Vue.extend({
  data() {
    return {
      AccountUnknown: false,
      CommonCurrentLanguage:"",
      IsAlreadyExistsOpen: false,
      IsNoPermissionOpen: false,
      OpenGeneralError: false,
    };
  },
  components: {
    UnhandleErrorDialog,
    NoPermission
  },
  methods: {
    CloseErrorOccured() {
      this.OpenGeneralError = false;
      this.IsNoPermissionOpen = false;
    },
    loadCountry() {
      this.$store
      .dispatch("GetCountries")
      .then(() => {
      })
      .catch((error) => {
        this.CheckError(error);
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    CheckError(e: any) {
      console.log(e + ' - '+ e.stack)
      if (e.response == undefined || e.response.status == undefined) {
        logtail.error(e);
        this.OpenGeneralError = true;
        return 400;
      }
      else if (e.response.status === 400) {
        logtail.error(e + ' - '+ e.stack);
        if (
          e.response.data.errors != undefined &&
          e.response.data.errors.externalAccountId != undefined
        ) {
          this.AccountUnknown = true;
        } else {
          this.OpenGeneralError = true;
          return 400;
        }
      } else if (e.response.status === 403) {
        if (
          this.CurrentUser != null &&
          this.CurrentUser.linkedAccounts != null
        ) {
          const account = this.CurrentUser.linkedAccounts.find(
            (p: any) => p.accountExternalId === this.AccountId
          ) as any;

          if (account == null) {
            this.AccountUnknown = true;
            return 999;
          }
          else {
            logtail.error(e);
            this.IsNoPermissionOpen = true;
            return 403
          }
        }
        else
        {
          logtail.error(e);
          this.IsNoPermissionOpen = true;
          return 403
        }
      } else if (e.response.status === 401) {
        this.$root.$emit("Unauthorized");
        return 401;
      } else if (e.response.status === 404) {
        this.OpenGeneralError = true;
        return 404;
      } else {
        logtail.error(e);
        return 0;
      }
    },
    LogError(e: any) {
      //console.log(e);
      //console.log(e.response);
      logtail.error(e);
    },
    ReturnToProjects() {
      this.PushToThisAccountRoute("projects");
    },
    ReturnToAccount() {
      this.PushToRoute("AccountSelector");
    },
    PushToRoute(name: string) {
      const lang = this.$route.params.lang;
      const to = this.$router.resolve({
        name,
        params: { lang },
      });
      this.$router.push(to.location);
    },
    PushToThisAccountRoute(name: string) {
      const accountId = this.$route.params.accountId;
      this.PushToAccountRoute(accountId, name);
    },
    PushToAccountRoute(accountId: string, name: string) {
      const lang = this.$route.params.lang;
      const to = this.$router.resolve({
        name,
        params: { lang, accountId },
      });
      this.$router.push(to.location);
    },
    CopyLink() {
      const el = document.createElement("textarea");
      el.value = window.location.href;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    OrderTranslations() {
      const lang = this.$route.params.lang;
      const id = this.Id;
      const name = "viewNewOrdersProject";

      const to = this.$router.resolve({
        name,
        params: { lang, id },
      });
      this.$router.push(to.location);
    },
    PushToRouteWithId(name: string, id: string) {
      const accountId = this.$route.params.accountId;
      const lang = this.$route.params.lang;
      const to = this.$router.resolve({
        name,
        params: { accountId, lang, id },
      });
      //alert(to.location);
      this.$router.push(to.location);
    },
    RedirectBilingualView(sourceLanguage: string, targetLanguage: string) {
      const accountId = this.$route.params.accountId;
      const lang = this.$route.params.lang;
      const id = this.Id;
      const name = "bilingualviewproject";
      const to = this.$router.resolve({
        name,
        params: { accountId, lang, id, sourceLanguage, targetLanguage },
        query: this.$route.query,
      });
      this.$router.push(to.location);
    },
    RedirectSingleKeyView(
      sourceLanguage: string,
      targetLanguage: string,
      keyId: string
    ) {
      const lang = this.$route.params.lang;
      const name = "viewsinglekey";
      const id = this.Id;
      const accountId = this.$route.params.accountId;
      const to = this.$router.resolve({
        name,
        params: { accountId, lang, id, keyId, sourceLanguage, targetLanguage },
        query: this.$route.query,
      });
      this.$router.push(to.location);
    },
    RedirectSingleKeyViewNoKey(sourceLanguage: string, targetLanguage: string) {
      const lang = this.$route.params.lang;
      const name = "viewsinglekeynokey";
      const id = this.Id;
      const accountId = this.$route.params.accountId;
      const to = this.$router.resolve({
        name,
        params: { accountId, lang, id, sourceLanguage, targetLanguage },
        query: this.$route.query,
      });
      this.$router.push(to.location);
    },
  
  },
  computed: {
    CountryList(): Country[] {
      return this.$store.state.Countries;
    },
    SupportedLanguages() {
      return this.$i18n.locale;
    },
    Id(): string {
      return this.$route.params.id;
    },
    AccountId(): string {
      return this.$route.params.accountId;
    },
    isAuthenticated(): boolean {
      return this.$store.state.IsAuthenticated;
    },
    CurrentUser(): User {
      return this.$store.state.CurrentUser;
    },
    UnknownAccount(): boolean {
      return this.$store.state.UnknownAccount;
    },
    hasLanguages(): boolean {
      if (
        this.$store.state.Languages == null ||
        this.$store.state.Languages.length === 0
      ) {
        return false;
      }
      return true;
    },
  },
});
