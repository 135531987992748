import { getInstance } from './auth0-plugin.js';
import { Trans } from "@/plugins/trans";

export const authenticationGuard = (to, from, next) => {
  const authService = getInstance();

  const guardAction = () => {
    if (authService.isAuthenticated) {
      return next();
    }
    //console.log(to)
    let lang = to.params.lang;

    if (!Trans.isLangSupported(lang)) {
      lang = Trans.getUserSupportedLang();
    }
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath,  }, ui_locales: lang });
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction();
  }

  authService.$watch('isLoading', (isLoading) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
};