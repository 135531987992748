import mixins from "vue-typed-mixins";
import Common from "@/Mixins/Common";
import User from "@/Types/User";

export default mixins(Common).extend({
  methods: {
    hasPermission(projectId: string | undefined, permission: string): boolean {
      if (!this.isAuthenticated || projectId == undefined) {
        return false;
      }
      const CurrentUser:User = this.$store.state.CurrentUser;


      if (CurrentUser != null && CurrentUser.linkedProject != null) {
        const project = CurrentUser.linkedProject.find(
          (p: any) => p.projectId === projectId
        ) as any;

        if (project != null) {
          if (project.permissions.some((e: string) => e == permission)) {
            return true;
          }
          if (project.permissions.some((e: string) => e == "Admin")) {
            return true;
          }
          if (project.permissions.some((e: string) => e == "Owner")) {
            return true;
          }
        }
      }
      if (CurrentUser != null && CurrentUser.linkedAccounts != null) {
        const project = CurrentUser.linkedAccounts.find(
          (p: any) => p.accountExternalId === this.AccountId
        ) as any;

        if (project != null) {
          if (project.accountPermissions.some((e: string) => e == "Owner")) {
            return true;
          }
          if (project.accountPermissions.some((e: string) => e == "Admin")) {
            return true;
          }
        }
      }
      return false;
    },
    hasPermissionAnyProject(permission: string): boolean {
      if (!this.isAuthenticated) {
        return false;
      }
      const CurrentUser = this.$store.state.CurrentUser;

      if (CurrentUser != null && CurrentUser.linkedProject != null) {
        CurrentUser.linkedProject.forEach((project: any) => {
          if (project != null) {
            if (project.permissions.some((e: string) => e == permission)) {
              return true;
            }
            if (project.permissions.some((e: string) => e == "Admin")) {
              return true;
            }
            if (project.permissions.some((e: string) => e == "Owner")) {
              return true;
            }
          }
        });
      }
      if (CurrentUser != null && CurrentUser.linkedAccounts != null) {
        const project = CurrentUser.linkedAccounts.find(
          (p: any) => p.externalId === this.AccountId
        ) as any;

        if (project != null) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    isAuthenticated(): boolean {
      return this.$store.state.IsAuthenticated;
    },
    CanManageProjects(): boolean {
      if (
        this.CurrentUser != null &&
        this.CurrentUser.linkedAccounts != null &&
        this.AccountId != null && 
        !this.UnknownAccount
      ) {
        const currentAccount = this.CurrentUser.linkedAccounts.find(
          (p: any) => p.accountExternalId === this.AccountId
        ) as any;

        if (currentAccount != null) {
          if (
            currentAccount.accountPermissions.some(
              (e: string) => e == "ManageProjects"
            )
          ) {
            return true;
          }
          if (
            currentAccount.accountPermissions.some((e: string) => e == "Admin")
          ) {
            return true;
          }
          if (
            currentAccount.accountPermissions.some((e: string) => e == "Owner")
          ) {
            return true;
          }
        }
      }

      return false;
    },
    CanManageAccount(): boolean {
      if (
        this.CurrentUser != null &&
        this.CurrentUser.linkedAccounts != null &&
        this.AccountId != null
      ) {
        const currentAccount = this.CurrentUser.linkedAccounts.find(
          (p: any) => p.accountExternalId === this.AccountId
        ) as any;

        if (currentAccount != null) {
          if (
            currentAccount.accountPermissions.some((e: string) => e == "Admin")
          ) {
            return true;
          }
          if (
            currentAccount.accountPermissions.some((e: string) => e == "Owner")
          ) {
            return true;
          }
        }
      }

      return false;
    },
    CanManageTeam(): boolean {
      if (
        this.CurrentUser != null &&
        this.CurrentUser.linkedAccounts != null &&
        this.AccountId != null
      ) {
        const currentAccount = this.CurrentUser.linkedAccounts.find(
          (p: any) => p.accountExternalId === this.AccountId
        ) as any;

        if (currentAccount != null) {
          if (
            currentAccount.accountPermissions.some(
              (e: string) => e == "ManageTeamMembers"
            )
          ) {
            return true;
          }
          if (
            currentAccount.accountPermissions.some((e: string) => e == "Admin")
          ) {
            return true;
          }
          if (
            currentAccount.accountPermissions.some((e: string) => e == "Owner")
          ) {
            return true;
          }
        }
      }

      return false;
    },
    auth0Loading() {
      return this.$auth.isLoading;
    },
  },
});
