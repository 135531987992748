<template>
  <v-app id="inspire">
    <router-view />
  </v-app>
</template>


<script lang="ts">
import mixins from "vue-typed-mixins";
import Common from "@/Mixins/Common";
import Authentication from "@/Mixins/Authentication";
import Account from "@/Mixins/Account";
import Project from "./Types/Project";


export default mixins(Common, Authentication, Account).extend({
  data: function () {
    return {
      drawer: false,
      OpenPasswordDialog: false,
      IsReAuthenticating: false,
      OpenSelectLanguage: false,
      SearchClose: true,
      SearchTerm: "",
      isSearchLoading: false,
      select: null,
      searchItems: [],
      filterString: "",
      timeout: {} as any,
      lastTimeStamp: 0,
      ShowProjectResults: true,
      SearchHasClick: false
    };
  },
  methods: {
    onBlurOfSearch() {
      if (this.filterString == "" || this.filterString == null) {
        this.SearchClose = true;
        this.isSearchLoading = false;
        this.searchItems = [];
        this.SearchTerm = "";
      }
    },
    ClearFilterString() {
      this.filterString = '';
      this.searchItems = [];
    },
    SearchToAccount() {
      this.ShowProjectResults = false;
    },
    SearchToProject() {
      this.ShowProjectResults = true;
    },
    setFilterString(e: any) {
      if (!e) {
        this.filterString = ''
      } else if (typeof e === 'string') {
        this.filterString = e
      } else if (typeof e === 'object') {
        this.filterString = e.srcElement.value
      }

      this.querySelections(this.filterString)
    },
    viewSelectLanguage() {
      this.OpenSelectLanguage = true;
    },
    logoOut() {
      this.$auth.logout({
        returnTo: process.env.VUE_APP_PUBLICSITE,
      });
    },
    OpenProjects() {
      if (this.$route.name != "projects") {
        this.PushToThisAccountRoute("projects");
      }
    },
    OpenOrders() {
      if (this.$route.name != "viewOrders") {
        this.PushToThisAccountRoute("viewOrders");
      }
    },
    ManagePaymentMethod() {
      this.PushToThisAccountRoute("ManagePaymentMethod");
    },
    OpenTasks() {
      if (this.$route.name != "viewTasksOverview") {
        this.PushToThisAccountRoute("viewTasksOverview");
      }
    },
    querySelections(v: string) {
      window.clearTimeout(this.timeout);
      if (v.trim().length != 0) {
        if ((v.trim().length % 3) == 0) {
          this.runQuerySelections(v, this.getTimeStamp())
        } else {
          this.timeout = window.setTimeout(() => this.runQuerySelections(v, this.getTimeStamp()), 300);
        }
      }


    },
    getTimeStamp(): number {
      var isPerformanceSupported = (
        window.performance &&
        window.performance.now() &&
        window.performance.timing &&
        window.performance.timing.navigationStart
      );

      var timeStampInMs = (
        isPerformanceSupported ?
          window.performance.now() +
          window.performance.timing.navigationStart :
          Date.now()
      );
      return timeStampInMs;
    },
    runQuerySelections(v: string, timestamp: number) {
      this.isSearchLoading = true
      window.clearTimeout(this.timeout);
      // Simulated ajax query
      setTimeout(() => {
        this.$store
          .dispatch("DoSearch", { searchTerm: v, AccountId: this.AccountId, ShowProjectResults: this.ShowProjectResults, ProjectId: this.ProjectId })
          .then((results: any) => {
            if (timestamp > this.lastTimeStamp) {
              this.lastTimeStamp = timestamp;
              results.forEach((element: any) => {
                element.searchTerms.forEach((b: string) => {
                  element.matchedSearchTerm = this.boldString(element.matchedSearchTerm, b);
                });
                element.matchedSearchTerm
              });
              this.searchItems = results;
            }


          })
          .catch((e: any) => {
            this.LogError(e);
            // this.PushToRoute("MajorError");
          });



        this.isSearchLoading = false
      }, 500)
    },
    boldString(str: string, substr: string) {
      var strRegExp = new RegExp(substr, 'i');
      return str.replace(strRegExp, '<strong>' + substr + '</strong>');
    },
    HandleClickSearch(term: any) {
      this.filterString = "";
      this.SearchTerm = "";
      const lang = this.$route.params.lang;
      const accountId = this.$route.params.accountId;
      const id = term.projectExternalId;
      this.SearchHasClick = true;
      if (term.matchObject == "Project") {
        const name = "viewproject";
        const to = this.$router.resolve({
          name,
          params: { accountId, lang, id },
        });
        //alert(to.location);
        this.ShouldRoute(to);
      }
      else if (term.matchObject == "ProjectKey" || term.matchObject == "ProjectKeyDetails") {

        const name = "viewsinglekey";
        const keyId = term.projectKeyExternalId;
        const sourceLanguage = lang;
        const targetLanguage = "fr";

        const to = this.$router.resolve({
          name,
          params: { accountId, lang, id, keyId, sourceLanguage, targetLanguage },
        });
        //alert(to.location);
        this.ShouldRoute(to);
      }
      else if (term.matchObject == "ProjectGlossary") {

        let name = "viewGlossary";
        const lang = this.$route.params.lang;
        let projectId = this.Id;
        const to = this.$router.resolve({
          name,
          params: { lang, projectId },
          query: { GlossaryId: term.matchObjectExternalId },
        });
        this.ShouldRoute(to);

      }
      else {
        alert(term.matchObject);
      }
    },
    ShouldRoute(to: any) {
      if (this.$route.fullPath != to.route.fullPath) {
        this.$router.push(to.location);
      }
    }
  },
  watch: {
    SearchTerm() {
      if (this.SearchTerm != null && this.SearchTerm != "") {
        this.$nextTick(function () {
          if (this.SearchHasClick) {
            this.searchItems = [];
            this.SearchTerm = "";
            this.SearchHasClick = false;
          }
        });
      }
    },
    ShowProjectResults() {
      this.runQuerySelections(this.filterString, this.getTimeStamp())
    },
  },
  computed: {
    IsInProject(): boolean {
      if (this.$route.path.indexOf("projects") != -1 && this.$route.params["id"] != undefined) {
        return true;
      }
      return false;
    },
    InAccountSelect(): Boolean {
      return this.currentRoute == "AccountSelector";
    },
    currentRoute(): any {
      return this.$route.name;
    },
    AccountName(): string {
      if (this.AccountOwnership == null || this.AccountOwnership.companyName == null) {
        return "";
      }
      return this.AccountOwnership.companyName;
    },
    CurrentProject(): Project | null {
      if (
        this.$store.state.Projects !== undefined &&
        this.$store.state.Projects.length !== 0 && this.IsInProject
      ) {
        const c = this.$store.state.Projects.find(
          (p: any) => p.externalId === this.$route.params["id"]
        );
        if (c == undefined) {
          return null;
        } else {
          return c;
        }
      } else {
        return null;
      }
    },
    CurrentProjectName(): string {
      if (this.CurrentProject == null) {
        return "";
      }
      return this.CurrentProject.name;
    },
    ProjectId(): string {
      if (this.CurrentProject == null) {
        return "";
      }
      return this.CurrentProject.externalId;
    }
  },
  mounted(){
    this.$store
        .dispatch("GetSupportedLanguages")
        .then(() => { })
        .catch((e: any) => {
          this.LogError(e);
          this.PushToRoute("MajorError");
        });
  }
});
</script>
<style>

.v-application .closed.mr-3,
.closed .mr-3 {
  margin-right: 0 !important;
}


.v-application .rounded-lg {
  border-radius: 20px !important;
}

.v-application {
  font-family: Montserrat !important;
}

.v-application .text-h6 {
  font-family: Montserrat !important;
  font-weight: bold;
  line-height: 1rem;
  letter-spacing: normal !important;
  font-size: 1.12rem !important;
}

@font-face {
  font-family: "Mazzard";
  src: local("Mazzard H Regular"), local("Mazzard-H-Regular"),
    url("assets/MazzardH-Regular.woff2") format("woff2"),
    url("assets/MazzardH-Regular.woff") format("woff"),
    url("assets/MazzardH-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-Light.eot");
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("assets/Montserrat-Light.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-Light.woff") format("woff"),
    url("assets/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-MediumItalic.eot");
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"),
    url("assets/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-MediumItalic.woff") format("woff"),
    url("assets/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-Thin.eot");
  src: local("Montserrat Thin"), local("Montserrat-Thin"),
    url("assets/Montserrat-Thin.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-Thin.woff") format("woff"),
    url("assets/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-ExtraLightItalic.eot");
  src: local("Montserrat ExtraLight Italic"),
    local("Montserrat-ExtraLightItalic"),
    url("assets/Montserrat-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-ExtraLightItalic.woff") format("woff"),
    url("assets/Montserrat-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-BoldItalic.eot");
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"),
    url("assets/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-BoldItalic.woff") format("woff"),
    url("assets/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-SemiBold.eot");
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("assets/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-SemiBold.woff") format("woff"),
    url("assets/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-ExtraLight.eot");
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"),
    url("assets/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-ExtraLight.woff") format("woff"),
    url("assets/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-ExtraBoldItalic.eot");
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"),
    url("assets/Montserrat-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-ExtraBoldItalic.woff") format("woff"),
    url("assets/Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-Italic.eot");
  src: local("Montserrat Italic"), local("Montserrat-Italic"),
    url("assets/Montserrat-Italic.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-Italic.woff") format("woff"),
    url("assets/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-Bold.eot");
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("assets/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-Bold.woff") format("woff"),
    url("assets/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-LightItalic.eot");
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"),
    url("assets/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-LightItalic.woff") format("woff"),
    url("assets/Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-BlackItalic.eot");
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"),
    url("assets/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-BlackItalic.woff") format("woff"),
    url("assets/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-SemiBoldItalic.eot");
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"),
    url("assets/Montserrat-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-SemiBoldItalic.woff") format("woff"),
    url("assets/Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-Regular.eot");
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("assets/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-Regular.woff") format("woff"),
    url("assets/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-Medium.eot");
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("assets/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-Medium.woff") format("woff"),
    url("assets/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-ExtraBold.eot");
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("assets/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-ExtraBold.woff") format("woff"),
    url("assets/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-Black.eot");
  src: local("Montserrat Black"), local("Montserrat-Black"),
    url("assets/Montserrat-Black.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-Black.woff") format("woff"),
    url("assets/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/Montserrat-ThinItalic.eot");
  src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"),
    url("assets/Montserrat-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("assets/Montserrat-ThinItalic.woff") format("woff"),
    url("assets/Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
</style>
