<template>
  <div>
    <div class="ChatWindow" v-if="Open">
      <v-card class="chatWindowWrap">
        <v-card-title class="headline blue darken-2 white--text" primary-title
          >{{ $t("Chat") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="CloseModal" class="ma-0">
            <v-icon>mdi-window-minimize</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-0 ma-0">
          <v-select
            :items="Projects"
            :label="$t('Project')"
            item-text="name"
            item-value="externalId"
            v-model="SelectProjects"
          ></v-select>
        </v-card-text>
        <v-card-text class="MessageWrap" id="ChatPanel">
          <div>
            <div v-for="m in message" :key="m.externalId">
              <div v-if="m.sender" class="MessageSender">
                <div class="messageBubbleWrap mr-2">
                  <div class="primary messageBubble text--lighten-5 grey--text">
                    {{ m.message }}
                    <v-btn
                      icon
                      @click="OpenLink(m.url)"
                      v-if="m.url != '' && m.url != null"
                    >
                      <v-icon color="white">mdi-link-variant</v-icon>
                    </v-btn>
                  </div>
                  <div
                    class="caption dateFont grey--text lighten-1--text mb-1"
                    v-if="m.showTime"
                  >
                    {{ m.createDate | DateTimeLocal }}
                  </div>
                </div>
              </div>
              <div v-else class="MessageFrom">
                <div class="messageBubbleWrap mr-2">
                  <div
                    class="
                      yellow
                      darken-4
                      messageBubble
                      text--lighten-5
                      grey--text
                    "
                  >
                    {{ m.message }}
                    <v-btn
                      icon
                      @click="OpenLink(m.url)"
                      v-if="m.url != '' && m.url != null"
                    >
                      <v-icon color="white">mdi-link-variant</v-icon>
                    </v-btn>
                  </div>
                  <div
                    class="caption dateFont grey--text lighten-1--text mb-1"
                    v-if="m.showTime"
                  >
                    {{ m.senderName }} <br />
                    {{ m.createDate | DateTimeLocal }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="grey lighten-3 px-2">
          <v-row
            no-gutters
            align="center"
            justify="center"
            style="flex-wrap: nowrap"
          >
            <v-col cols="8" class="flex-grow-0 flex-shrink-1">
              <div>
                <v-textarea
                  name="input-7-1"
                  label=""
                  auto-grow
                  background-color="white"
                  outlined
                  rows="1"
                  row-height="15"
                  hide-details
                  v-model="TextMessage"
                ></v-textarea>
              </div>
            </v-col>
            <v-col cols="1" class="flex-shrink-1 flex-grow-1">
              <v-tooltip bottom v-if="CurrentUrl != ''">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @dblclick="RemoveLink">
                    <v-icon>mdi-link-variant</v-icon>
                  </v-btn>
                </template>
                <span>{{ HasLinkTo }}</span>
              </v-tooltip>
            </v-col>

            <v-col cols="3" class="flex-grow-1 flex-shrink-1"
              ><v-btn
                elevation="2"
                rounded
                block
                color="primary"
                @click="SendMessage"
                >Send</v-btn
              ></v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div class="ClosedChatWindow" v-else>
      <v-card>
        <v-card-title
          class="headline blue darken-2 white--text py-2"
          primary-title
          >{{ $t("Chat") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="OpenModal" class="ma-0">
            <v-icon>mdi-window-maximize</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text> </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import Projects from "@/Mixins/Projects";
import Common from "@/Mixins/Common";

export default mixins(Projects, Common).extend({
  data() {
    return {
      LanguageId: [],
      Open: false,
      OpenGeneralError: false,
      text: "",
      SelectProjects: "",
      Loading: true,
      blukMessage: [] as any,
      message: [] as any,
      TextMessage: "",
      CurrentUrl: "",
      UrlKey: "",
      keyexternalId: "",
      HasLinkTo: "",
      rules: {
        required: (v: any) => !!(v && v.length) || this.$i18n.t("REQUIRED"),
      },
    };
  },
  methods: {
    CloseModal() {
      this.Open = false;
    },
    OpenModal() {
      this.Open = true;
      if (this.SelectProjects == "") {
        if (
          this.RouteName == "viewproject" ||
          this.RouteName == "bilingualviewproject" ||
          this.RouteName == "viewsinglekey" ||
          this.RouteName == "viewsinglekeynokey" ||
          this.RouteName == "viewIntergration" ||
          this.RouteName == "viewscreenshots" ||
          this.RouteName == "viewProjectTasks" ||
          this.RouteName == "viewTasksById" ||
          this.RouteName == "CreateProjectTasks" ||
          this.RouteName == "viewprojectusers" ||
          this.RouteName == "viewIntergrationbyid"
        ) {
          this.SelectProjects = this.Id;
        }
      }
    },
    GetInitMessages() {
      var checkTime = new Date().getTime();
      //alert("Test");
      this.$store
        .dispatch("GetChatMessages", this.AccountId)
        .then((response: any) => {
          //alert(response);
          this.blukMessage = response;
          this.blukMessage.forEach((element: any) => {
            element.checkTime = checkTime;
          });
          this.$nextTick(function () {
            var element = document.getElementById("ChatPanel");

            if (element != null) {
              element.scrollTop = element.scrollHeight;
            }
          });
        })
        .catch((error) => {
          this.CheckError(error);
        });
    },
    GetSinceMessages(project: string) {
      if (this.blukMessage == null) {
        return;
      }
      let i = this.blukMessage.find(
        (e: any) => e.projectExternalId === project
      );
      if (i != null) {
        let re = {
          projectId: project,
          sinceTime: i.checkTime,
          AccountId: this.AccountId,
        };
        this.$store
          .dispatch("GetChatSinceMessages", re)
          .then((response: any) => {
            if (response.length != 0) {
              response.forEach((element: any) => {
                i.messages = i.messages.filter((e: any) => {
                  return e.externalId != element.externalId;
                });
                i.messages.push(element);
              });

              this.ShowSelectProjects();
              this.$nextTick(function () {
                var element = document.getElementById("ChatPanel");

                if (element != null) {
                  element.scrollTop = element.scrollHeight;
                }
              });
            }
          })
          .catch((error) => {
            this.CheckError(error);
          });
      }
    },
    ShowSelectProjects() {
      if (this.SelectProjects != "") {
        let i = this.blukMessage.find(
          (e: any) => e.projectExternalId === this.SelectProjects
        );
        if (i != null) {
          this.message = i.messages;
          this.$nextTick(function () {
            var element = document.getElementById("ChatPanel");

            if (element != null) {
              element.scrollTop = element.scrollHeight;
            }
          });
        } else {
          this.message = [];
          this.$nextTick(function () {
            var element = document.getElementById("ChatPanel");

            if (element != null) {
              element.scrollTop = element.scrollHeight;
            }
          });
        }
      }
    },
    SendMessage() {
      this.TextMessage = this.TextMessage.trim();

      if (this.TextMessage.length != 0) {
        this.$store
          .dispatch("PostChatMessages", {
            projectId: this.Id,
            Payload: {
              message: this.TextMessage,
              ProjectId: this.SelectProjects,
              ProjectKeyexternalId: this.keyexternalId,
              Url: this.CurrentUrl,
            },
            AccountId: this.AccountId,
          })
          .then((response: any) => {
            this.TextMessage = "";
            this.message.push(response);
            this.RemoveLink();
            this.$nextTick(function () {
              var element = document.getElementById("ChatPanel");

              if (element != null) {
                element.scrollTop = element.scrollHeight;
              }
            });
          })
          .catch((error) => {
            this.CheckError(error);
          });
      }
    },
    RemoveLink() {
      this.CurrentUrl = "";
      this.UrlKey = "";
      this.keyexternalId = "";
    },
    OpenLink(m: any) {
      //alert(m)
      window.location.href = m;
      //window.history.pushState(m, );
    },
  },
  created() {},
  watch: {
    HasAccount() {
      if (this.HasAccount && this.isAuthenticated) {
        this.GetInitMessages();
      }
    },
    isAuthenticated() {
      if (this.HasAccount && this.isAuthenticated) {
        this.GetInitMessages();
      }
    },
    SelectProjects() {
      this.ShowSelectProjects();
    },
  },
  computed: {
    HasAccount() {
      if (this.AccountId == null || this.AccountId == undefined) {
        return false;
      }
      return true;
    },
    RouteName(): string {
      if (this.$route.name == null || this.$route.name == undefined) {
        return "";
      }
      return this.$route.name.toString();
    },
    Id(): string {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.Loading = true;
    this.$root.$on("OpenChat", (text: any) => {
      this.SelectProjects = text.projectId;
      this.CurrentUrl = text.url;
      this.UrlKey = text.key;
      this.keyexternalId = text.keyexternalId;
      this.HasLinkTo = this.$i18n
        .t("HasLinkTo", {
          key: text.key,
        })
        .toString();
      this.Open = true;
      this.$nextTick(function () {
        var element = document.getElementById("ChatPanel");

        if (element != null) {
          element.scrollTop = element.scrollHeight;
        }
      });
      this.text = text;
      //console.log(text);
    });
    this.$root.$on("ChatMessage", (text: any) => {
      this.GetSinceMessages(text.ProjectId);
    });
    //alert("Test");
    if (this.HasAccount) {
      this.GetInitMessages();
    }
  },
});
</script>

<style scoped>
.ChatWindow {
  width: 400px;
  min-height: 400px;
  position: fixed;
  bottom: 0px;
  right: 0px;
}
.ClosedChatWindow {
  width: 400px;
  max-height: 50px;
  position: fixed;
  bottom: 0px;
  right: 0px;
}
.chatWindowWrap {
  height: 100%;
}

.MessageWrap {
  height: 250px;
  margin: 0px;
  padding: 0px !important;
  text-align: center;
  overflow-y: scroll;
}
.MessageSender {
  margin-left: auto;
  margin-right: 0;
  max-width: 80%;
}
.MessageFrom {
  margin-left: 8px;
  margin-right: auto;
  max-width: 80%;
}
.MessageFrom .messageBubbleWrap {
  text-align: left;
}
.messageBubble {
  border-radius: 15px;
  padding: 5px 15px;
  display: inline-block;
  text-align: right;
  margin-top: 5px;
}
.messageBubbleWrap {
  text-align: right;
}
.v-application .caption.dateFont {
  font-size: 0.6rem !important;
  line-height: 0.8rem;
}
</style>