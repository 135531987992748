import mixins from "vue-typed-mixins";
import Common from "@/Mixins/Common";
import Project from "@/Types/Project";
import Language from "@/Types/Language";

export default mixins(Common).extend({
  data() {
    return {
      Projects: [] as Project[],
      selectProjectId: "",
      CurrentLanguage:""
    };
  },
  methods: {
    PushToRoute(name: string) {
      const lang = this.$route.params.lang;
      const accountId = this.AccountId;
      const to = this.$router.resolve({
        name,
        params: { accountId, lang },
      });
      this.$router.push(to.location);
    },
    SortProjects() {
      if (this.HasProjects) {
        const projects = this.ProjectSource;
        projects.forEach((element: Project) => {
          element.languages.forEach((language: Language) => {
            if (language != undefined && language != null) {
              if (
                language.customName == undefined ||
                language.customName == null ||
                language.customName.trim() == ""
              ) {

                const languageTitle = `Language-${language.isoLocalId}`
                language.languageTitle = `${this.$i18n.t(languageTitle)} (${language.isoLocalId})`;
              } else {
                language.languageTitle = `${language.customName} (${language.isoLocalId})`;
              }
            }
          });
          const masterLanguage = element.languages.find(
            (p: Language) => p.isDefaultLanguage
          ) as Language;

          const newlanguages = [];
          if (masterLanguage != undefined) {
            newlanguages.push(masterLanguage);
          }

          let otherLanguage = element.languages.filter(
            (i: Language) => !i.isDefaultLanguage
          );
          otherLanguage = otherLanguage.sort(function (a: Language, b: Language) {
            const nameA = a.languageTitle.toUpperCase(); // ignore upper and lowercase
            const nameB = b.languageTitle.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          otherLanguage.forEach((language: Language) => {
            newlanguages.push(language);
          });

          element.languages = newlanguages;
        });
        this.Projects = projects;
      }
    },
    GetProjects() {
      const h = this;
      this.$store
        .dispatch("GetProjects", this.AccountId)
        .then(() => { })
        .catch((e) => {

          const errorCode = this.CheckError(e);
          if (errorCode == 403 || errorCode == 999) {
            alert(h.AccountUnknown);
          }
        });
    }
  },
  computed: {
    isAuthenticated(): boolean {
      return this.$store.state.IsAuthenticated;
    },
    HasProjects(): boolean {
      if (
        this.$store.state.Projects == null ||
        this.$store.state.Projects.length === 0
      ) {
        return false;
      }
      return true;
    },
    AccountId(): string {
      return this.$route.params.accountId;
    },
    ProjectsLoadError(): boolean {
      return this.$store.state.ProjectsLoadError;
    },
    ProjectSource(): Project[] {
      return this.$store.state.Projects;
    },
    ProjectsLoading(): boolean {
      return this.$store.state.ProjectsLoading || this.$store.state.UserLoading;
    },
    ProjectsLoaded(): boolean {
      return this.$store.state.ProjectsLoaded;
    },
    ProjectsLoadingNoProjects(): boolean {
      return this.ProjectsLoading && !this.HasProjects && !this.UnknownAccount;
    },
    NoProjects(): boolean {
      return (
        !this.HasProjects &&
        !this.ProjectsLoading &&
        !this.$store.state.UserLoading
      );
    },
  },
  watch: {
    ProjectsLoadError() {
      if (this.ProjectsLoadError) {
        if (
          this.AccountId == undefined ||
          this.AccountId == null ||
          this.AccountId == ""
        ) {
          this.PushToRoute("AccountSelector");
        } else {
          this.PushToRoute("ProjectFailedLoad");
        }
      }
    },
    isAuthenticated() {
      if (
        !this.HasProjects &&
        !this.$store.state.ProjectsLoading &&
        this.isAuthenticated
      ) {
        if (
          this.AccountId == undefined ||
          this.AccountId == null ||
          this.AccountId == ""
        ) {
          //alert("Bad 5");
          //alert(this.$route.params.accountId);
          //console.log(this.$route.params);
          // this.PushToRoute("AccountSelector");
        } else {
          this.GetProjects();
        }
      }
    },
    SupportedLanguages() {
      if(this.CurrentLanguage != this.SupportedLanguages)
      {
        this.SortProjects();
        this.CurrentLanguage = this.SupportedLanguages;
      }
    },
    ProjectSource() {
      if (this.HasProjects && this.ProjectSource != null) {
        this.SortProjects();
      }
    },
  },
  mounted() {
    if (
      !this.HasProjects &&
      !this.$store.state.ProjectsLoading &&
      this.isAuthenticated
    ) {
      if (
        this.AccountId == undefined ||
        this.AccountId == null ||
        this.AccountId == ""
      ) {
        this.PushToRoute("AccountSelector");
      } else {
        this.$store.dispatch("GetProjects", this.AccountId).catch((e) => {
          const errorCode = this.CheckError(e);
          if (errorCode == 403 || errorCode == 999) {

            this.AccountUnknown = true;
          }
        });
      }
    } else {
      if (this.HasProjects) {
        this.SortProjects();
      }
    }
  },
});
