<template>
  <div>
    <v-dialog v-model="openDialogWatch" max-width="600" persistent no-click-animation>
      <v-form ref="newLanguage" lazy-validation>
        <v-card>
          <v-card-title class="text-h6 backgroundBrand white--text" primary-title>{{ $t("SelectLanguage") }}
            <v-spacer></v-spacer>
            <v-btn icon dark @click="CloseSelectLanguage" class="ma-0">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-6 text-subtitle-1">
            <ul class="ml-0 pl-0">
              <li v-for="Lang in SupportedLanguages" v-bind:key="Lang.isoLocalId" class="mb-2 pointer" @click="ChangeLanguage(Lang)">{{ Lang.nativeName }}</li>
            </ul>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="CloseSelectLanguage">{{ $t("Close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import CoreLanguage from "@/Types/CoreLanguages";
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  data() {
    return {
      openDialogWatch: false,
    };
  },
  computed: {
    SupportedLanguages(): CoreLanguage[] {
      return this.$store.state.SupportedLanguages;
    }
  },
  methods: {
    CloseSelectLanguage() {
      this.$emit("CloseSelectLanguage");
    },
    ChangeLanguage(Lang:CoreLanguage) {
      let lang = Lang.isoLocalId;
      const to = this.$router.resolve({ params: {lang  } });
      this.$router.push(to.location);
      this.CloseSelectLanguage();
    }
  },
  watch: {
    openDialog() {
      this.openDialogWatch = this.openDialog;
    },
  },
  props: {
    openDialog: Boolean,
    projectId: String,
  },
});
</script>

<style scoped>
ul {   
   list-style: none;
}
li {
  font-size: 20px;
}
</style>