import axios from 'axios';
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from '@/constants/trans';
import i18n from '../i18n';

const Trans = {
    get defaultLanguage() {
        return DEFAULT_LANGUAGE;
    },
    get supportedLanguages() {
        return SUPPORTED_LANGUAGES;
    },
    get currentLanguage() {
        return i18n.locale;
    },
    set currentLanguage(lang) {
        i18n.locale = lang;
    },
    /**
     * Gets the first supported language that matches the user's
     * @return {String}
     */
    getUserSupportedLang() {

        const userPreferredLang = Trans.getUserLang();

        // Check if user preferred browser lang is supported
        if (Trans.isLangSupported(userPreferredLang.lang)) {
            return userPreferredLang.lang;
        }
        // Check if user preferred lang without the ISO is supported
        if (Trans.isLangSupported(userPreferredLang.langNoISO)) {
            return userPreferredLang.langNoISO;
        }
        return Trans.defaultLanguage;
    },
    /**
     * Returns the users preferred language
     */
    getUserLang() {
        const lang = window.navigator.language || Trans.defaultLanguage;
        return {
            lang,
            langNoISO: lang.split('-')[0],
        };
    },
    /**
     * Sets the language to various services (axios, the html tag etc)
     * @param {String} lang
     * @return {String} lang
     */
    setI18nLanguageInServices(lang: string) {
        Trans.currentLanguage = lang;
        axios.defaults.headers.common['Accept-Language'] = lang;
        const i = document.querySelector('html');
        if (i !== null) {
            i.setAttribute('lang', lang);
        }
        return lang;
    },
    /**
     * Loads new translation messages and changes the language when finished
     * @param lang
     * @return {Promise<any>}
     */
    changeLanguage(lang: string) {
        if (!Trans.isLangSupported(lang)) {
            return Promise.reject(new Error('Language not supported'));
        }
        if (i18n.locale === lang) {
            return Promise.resolve(lang);
        } // has been loaded prior

        import(`@/locales/${lang}.json`).then((msg) => {
            i18n.setLocaleMessage(lang, msg.default || msg);
            i18n.locale = lang;
            return Trans.setI18nLanguageInServices(lang);
        });
    },
    /**
     * Async loads a translation file
     * @param lang
     * @return {Promise<*>|*}
     */

    /**
     * Checks if a lang is supported
     * @param {String} lang
     * @return {boolean}
     */
    isLangSupported(lang: string) {
        return Trans.supportedLanguages.includes(lang);
    },
    /**
     * Checks if the route's param is supported, if not, redirects to the first supported one.
     * @param {Route} to
     * @param {Route} from
     * @param {Function} next
     * @return {*}
     */
    // routeMiddleware (to: any, from: any, next: any) {
    //   // Load async message files here
    //   const lang = to.params.lang
    //   if (!Trans.isLangSupported(lang)) return next(Trans.getUserSupportedLang())
    //   return Trans.changeLanguage(lang).then(() => next()).catch()
    // }
};

export { Trans };
